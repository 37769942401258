import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    selectGameState,
    selectScreenState,
    selectRomsetState,
    selectRomsetUpdatesState,
    loadGame,
    loadScreen,
    loadRomsetUpdates,
    loadRomset,
    reloadGameDetail,
    changeRomsetVersion,
    selectCategoriesState,
    loadMachineCategories
} from './gameDetailSlice';

import {reload} from '../gameList/gameListSlice';

import {
    Col,
    Card,
    Spinner,
    Row,
    ListGroup,
    Table,
    Pagination,
    Container,
} from 'react-bootstrap';

import Breadcrumb from 'react-bootstrap/Breadcrumb'

import {
    Link,
    useParams
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { faEquals, faFileImport } from '@fortawesome/free-solid-svg-icons'
import Stack from 'react-bootstrap/Stack';

export default function GameDetail() {

    const [oldTableHeigth, setOldTableHeigth] = useState(null);

    const gameState = useSelector(selectGameState);
    const screenState = useSelector(selectScreenState);
    const romsetUpdatesState = useSelector(selectRomsetUpdatesState);
    const romsetState = useSelector(selectRomsetState);
    const categoriesState = useSelector(selectCategoriesState);

    const romesetTableContainerRef = useRef(null);
    const romesetTableOverlayRef = useRef(null);
    
    const dispatch = useDispatch();
    let { game } = useParams();

    useEffect(() => {
        if(gameState.status === 'idle' && !gameState.loaded) {
            dispatch(loadGame(game));
        }
        if(screenState.status === 'idle' && !screenState.loaded) {
            dispatch(loadScreen(game));
        }
        if(romsetUpdatesState.status === 'idle' && !romsetUpdatesState.loaded) {
            dispatch(loadRomsetUpdates(game));
        }
        if(romsetState.status === 'idle' && !romsetState.loaded && romsetUpdatesState.loaded) {
            dispatch(loadRomset({
                gameName: game, 
                version: romsetUpdatesState.target
            }));
        }
        if(categoriesState.status === 'idle' && !categoriesState.loaded) {
            dispatch(loadMachineCategories(game));
        }
    });

    useEffect(() => {
        if (!romesetTableContainerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if(oldTableHeigth && parseInt(romesetTableContainerRef.current.clientHeight) > parseInt(oldTableHeigth)) {
                const verticalScroll = parseInt(romesetTableContainerRef.current.clientHeight) - parseInt(oldTableHeigth)
                window.scrollBy(0, verticalScroll)
                setOldTableHeigth(null)
            }
        });
        resizeObserver.observe(romesetTableContainerRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [oldTableHeigth]);

    let cloneOf = 'This is an original title';
    if (gameState.data.cloneOf) {
        cloneOf = (
            <span>
                Original title:
                <Link to={"/gameDetail/" + gameState.data.cloneOf}
                    onClick={ () => dispatch(reloadGameDetail()) }>
                    {' ' + gameState.data.cloneOf}
                </Link>
            </span>
        );
    }

    let gameDetail = <Spinner animation="border" variant="primary" />;
    if(gameState.loaded) {
        gameDetail = (
            <Row>
                <Col xs={4}>
                    <Card className="shadow-sm flex-fill">
                        <img class="rounded mx-auto d-block p-2"
                            src={"https://mdb.dfri.info/snap/" + gameState.data.name + ".png"}
                            style={{"maxWidth": "100%"}}>
                        </img>
                    </Card>
                </Col>
                <Col xs={8}>
                    <ListGroup>
                        <ListGroup.Item>Description: <strong>{gameState.data.description}</strong></ListGroup.Item>
                        <ListGroup.Item>Name: {gameState.data.name}</ListGroup.Item>
                        <ListGroup.Item>{cloneOf}</ListGroup.Item>
                        <ListGroup.Item>Manufacturer: {gameState.data.manufacturer}</ListGroup.Item>
                        <ListGroup.Item>Year: {gameState.data.year}</ListGroup.Item>
                        <ListGroup.Item>Supported from {gameState.data.sinceBuild} to {gameState.data.lastBuild}</ListGroup.Item>
                        <ListGroup.Item>Driver sourcefile: {gameState.data.sourcefile}</ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        );
    }
    
    let screens = [];
    screenState.data.forEach(element => {
        screens.push(
            <tr>
                <td>{element['tag']}</td>
                <td>{element['type']}</td>
                <td>{element['width']}</td>
                <td>{element['height']}</td>
                <td>{element['refresh']}</td>
            </tr>
        );
    });

    const tdClassName = function(e, flag) {
        return e['creationFlags'] !== -1 && (e['creationFlags'] & flag) > 0 ? 'mod' : '';
    }

    function BoolItem(props) {
        if(props.value === null) {
            return '-';
        } else if(props.value) {
            return <FontAwesomeIcon icon={faCheckSquare}/>
        } else {
            return <FontAwesomeIcon icon={faSquare}/>
        }
    }

    function CreationFlagIcon(props) {
        if(props.value === -1) {
            return <FontAwesomeIcon icon={faFileImport}/>
        } else if(props.value === 0) {
            return <FontAwesomeIcon icon={faEquals}/>
        } else {
            return <FontAwesomeIcon icon={faEdit}/>
        }
    }

    let romsetTable = (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
        </div>
    )
    //if(romsetState.loaded) {
    if(romsetUpdatesState.current && romsetState.data[romsetUpdatesState.current]) {
        if(romsetState.loaded) {
            romesetTableOverlayRef.current.style = {}
        }
        let romsetRows = [];
        romsetState.data[romsetUpdatesState.current].forEach(element => {
            romsetRows.push(
                <tr>
                    <td>{element['name']}</td>
                    <td className={tdClassName(element, 1)}>{element['bios']}</td>
                    <td className={tdClassName(element, 2)}>{element['rom_size']}</td>
                    <td className={tdClassName(element, 4)}>{element['checksum']}</td>
                    <td className={tdClassName(element, 16)}>
                        <div className='text-truncate' style={{"maxWidth": "100px"}}>
                            {element['sha1']}
                        </div>
                    </td>
                    <td className={tdClassName(element, 32)}>{element['merge']}</td>
                    <td className={tdClassName(element, 64) + ' text-truncate'} style={{"maxWidth": "100px"}}>
                        {element['region']}
                    </td>
                    <td className={tdClassName(element, 128)}>{element['offset']}</td>
                    <td className={tdClassName(element, 256)}>{element['status']}</td>
                    <td className={tdClassName(element, 512) + ' bool-col'}>
                        <BoolItem value={element['dispose']}/>
                    </td>
                    <td className={tdClassName(element, 2048) + ' bool-col'}>
                        <BoolItem value={element['soundonly']}/>
                    </td>
                    <td className={tdClassName(element, 4096) + ' bool-col'}>
                        <BoolItem value={element['optional']}/>
                    </td>
                    <td className="bool-col">
                        <CreationFlagIcon value={element['creationFlags']}/>
                    </td>
                </tr>
            );
        });
        romsetTable = (    
            <Table size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Bios</th>
                        <th>Size</th>
                        <th>Checksum</th>
                        <th>Sha1</th>
                        <th>Merge</th>
                        <th>Region</th>
                        <th>Offset</th>
                        <th>Status</th>
                        <th>Dispose</th>
                        <th>Soundonly</th>
                        <th>Optional</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {romsetRows}
                </tbody>
            </Table>
        );
    }

    let romsetUpdates = <Spinner animation="border" variant="primary" />;
    if (romsetUpdatesState.loaded) {

       /*
        *
        *                 |                paginator size                    |
        *            1    |tail                                          head|                 pages
        *           [][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][]
        *                                       ^
        *                                       page
        *                 |      tail size     ||         head size          |
        *
        */
        const pageVal = romsetUpdatesState.currentIndex + 1;
        const pages = romsetUpdatesState.data.length;
        const paginatorSize = 10;
        const tailSize = Math.floor(paginatorSize / 2);
        const headSize = paginatorSize - tailSize;
        let tail = -1;
        if(pageVal - tailSize < 1) {
            tail = 1;
        } else if(pageVal - tailSize + paginatorSize > pages) {
            tail = pages - paginatorSize + 1 < 1 ? 1 : pages - paginatorSize + 1;
        } else {
            tail = pageVal - tailSize;
        }
        const head = tail + paginatorSize - 1 >= pages ? pages : tail + paginatorSize - 1;

        const versionSelected = (i) => {
            romesetTableOverlayRef.current.style.top = romesetTableContainerRef.current.offsetTop + "px"
            romesetTableOverlayRef.current.style.left = romesetTableContainerRef.current.offsetLeft + "px"
            romesetTableOverlayRef.current.style.width = romesetTableContainerRef.current.clientWidth + "px"
            romesetTableOverlayRef.current.style.height = romesetTableContainerRef.current.clientHeight + "px"
            romesetTableOverlayRef.current.style.display = "block"
            setOldTableHeigth(romesetTableContainerRef.current.clientHeight)
            dispatch(changeRomsetVersion(romsetUpdatesState.data[i-1].buildSeq));
        }

        let items = [];
        if(tail > 1) {
            items.push(
                <Pagination.Item key={romsetUpdatesState.data[0].buildSeq}
                        active={romsetUpdatesState.data[0].buildSeq === romsetUpdatesState.current}
                        onClick={() => versionSelected(1)}>
                    {romsetUpdatesState.data[0].build}
                </Pagination.Item>
            );
            items.push(
                <Pagination.Ellipsis />
            );
        }
        for(let i = parseInt(tail); i <= parseInt(head); i++) {
            items.push(
                <Pagination.Item key={romsetUpdatesState.data[i-1].buildSeq} 
                        active={romsetUpdatesState.data[i-1].buildSeq === romsetUpdatesState.current}
                        onClick={() => versionSelected(i)}>
                    {romsetUpdatesState.data[i-1].build}
                </Pagination.Item>
            );
        }
        if(head < pages) {
            items.push(
                <Pagination.Ellipsis />
            );
            items.push(
                <Pagination.Item key={romsetUpdatesState.data[romsetUpdatesState.data.length - 1].buildSeq}
                        active={romsetUpdatesState.data[romsetUpdatesState.data.length - 1].buildSeq === romsetUpdatesState.current}
                        onClick={() => versionSelected(romsetUpdatesState.data.length)}>
                    {romsetUpdatesState.data[romsetUpdatesState.data.length - 1].buildSeq}
                </Pagination.Item>
            );
        }

        romsetUpdates = (
            <div className="d-flex justify-content-center">
                <Pagination>
                    {items}
                </Pagination>
            </div>
        );
    }

    let categories = <Spinner animation="border" variant="primary" />;
    if(categoriesState.loaded) {
        let tmp = [];
        categoriesState.data.system.forEach(e => {
            let bi = e.filter(i => i.name !== 'game').map(i => {
                return <Breadcrumb.Item>
                    <Link to={"/"} onClick={ () => dispatch(reload({'singleCategory': i})) }>
                        {i.name}
                    </Link>
                </Breadcrumb.Item>
            });
            tmp.push(<div className='me-3'><Breadcrumb>{[<Breadcrumb.Item>System</Breadcrumb.Item>].concat(bi)}</Breadcrumb></div>);
        });
        categoriesState.data.user.forEach(e => {
            let bi = e.filter(i => i.name !== 'game').map(i => {
                return <Breadcrumb.Item>
                    <Link to={"/"} onClick={ () => dispatch(reload({'singleCategory': i})) }>
                        {i.name}
                    </Link>
                </Breadcrumb.Item>
            });
            tmp.push(<div><Breadcrumb>{[<Breadcrumb.Item>User</Breadcrumb.Item>].concat(bi)}</Breadcrumb></div>);
        });
        categories = <Stack direction="horizontal" gap={3}>{tmp}</Stack>;
    }

    return(
        <Container>
            <div className="py-3">
                {gameDetail}
            </div>
            <Row className="py-3">
                <Col xs={4}>
                    <h5>Driver</h5>
                    <ListGroup>
                        <ListGroup.Item>Status: {gameState.data.driverStatus}</ListGroup.Item>
                        <ListGroup.Item>Emulation: {gameState.data.driverEmulation}</ListGroup.Item>
                        <ListGroup.Item>Cockatail mode: {gameState.data.driverCocktail}</ListGroup.Item>
                        <ListGroup.Item>Savestate: {gameState.data.driverSavestate}</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col xs={8}>
                    <h5>Screen</h5>
                    <div>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Tag</th>
                                    <th>Type</th>
                                    <th>Width</th>
                                    <th>Height</th>
                                    <th>Frequency</th>
                                </tr>
                            </thead>
                            <tbody>
                                {screens}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h5>Categories</h5>
                    {categories}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h5>Romset</h5>
                    <div ref={romesetTableContainerRef}>
                        <div ref={romesetTableOverlayRef} className='componentOverlay d-flex justify-content-center align-items-center'>
                            <Spinner animation="border" variant="primary"/>
                        </div>
                        {romsetTable}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {romsetUpdates}
                </Col>
            </Row>
        </Container>
    )
}