import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile, faGrinStars, faFrown } from '@fortawesome/free-regular-svg-icons'
import { useTranslation, Trans } from 'react-i18next';

export default function StatusIcon({machineName, status, keyContext}) {
    const { t } = useTranslation();
    const label = t('statusIcon.' + keyContext)
    const statusLabel = t('misc.' + status).toLowerCase()
    const statusIcons = {
        'good': faGrinStars,
        'imperfect': faSmile,
        'preliminary': faFrown,
    } 
    return (
        <OverlayTrigger 
            key={`${machineName}-otk-${keyContext}`}
            placement={'top'}
            overlay={
                <Tooltip id={`${machineName}-tooltip-${keyContext}`}>
                    {`${label} ${statusLabel}`}
                </Tooltip>
            }
        >
            <div>
                <FontAwesomeIcon icon={statusIcons[status]} />
            </div>
        </OverlayTrigger>
    );
}