import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiManager } from '../apiManager'

export const runMachine = createAsyncThunk(
    'player/runMachine',
    async (machineName) => {
        const api = {
            "name": "machineRun",
            "method": "get"
        }
        const r = {
            "variables": {
                "name": machineName
            },
            "data": {}
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const runStatus = createAsyncThunk(
    'player/runStatus',
    async () => {
        const api = {
            "name": "runStatus",
            "method": "get"
        }
        const r = {
            "variables": {},
            "data": {}
        }
        const response = await apiManager(api, r);
        return response;
    }
);

const initialState = {
    running: false,
    show: false,
    machine: null,
    data: []
};

export const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        close: (state, action) => {
            state.machine = null;
            state.show = false;
            state.running = false;
        },
        machineSelected: (state, action) => {
            state.machine = action.payload;
            state.status = 'idle';
            state.loaded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(runMachine.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(runMachine.fulfilled, (state, action) => {
                state.status = 'idle';
                state.loaded = true;
                state.data = [`$ ${action.payload.data.cmd}`];
                state.show = true;
                state.running = true;
            })
            .addCase(runMachine.rejected, (state) => {
                state.status = 'idle';
                state.loaded = true;
                state.machine = null;
            })
            .addCase(runStatus.fulfilled, (state, action) => {
                state.status = 'idle';
                state.loaded = true;
                state.data = action.payload.data.stdout;
                state.running = action.payload.data.runFlag;
                if(action.payload.data.runFlag === false)
                    state.data.push("Stopped");
            })
            .addCase(runStatus.rejected, (state) => {
                state.running = false;
            })
    },
});

export const { close, machineSelected } = playerSlice.actions;
export const selectData = (state) => state.player.data;
export const selectShow = (state) => state.player.show;
export const selectMachine = (state) => state.player.machine;
export const selectRunning = (state) => state.player.running;
export default playerSlice.reducer;