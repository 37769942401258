import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Dropdown, ButtonGroup, Modal, Button, Form, FormControl, Row, Col, Container, ListGroup, Nav } from 'react-bootstrap';
import { selectFilter, filterSelected, hideYearSelection, hideVersionSelection, selectOrderCol, selectOrderDir, orderSelected } from './homeListSlice'
import { useTranslation } from 'react-i18next';
import CategorySelector from './CategorySelector';
import { selectLoggedIn } from '../login/loginSlice';

export default function FilterPanel() {

    const filter = useSelector(selectFilter);
    const orderCol = useSelector(selectOrderCol);
    const orderDir = useSelector(selectOrderDir);

    const [searchText, setSearchText] = useState(filter.filter);

    const [yearStartSliderValue, setYearStartSliderValue] = useState(filter.yearStart < 0 ? filter.yearMin : filter.yearStart);
    const [yearEndSliderValue, setYearEndSliderValue] = useState(filter.yearEnd < 0 ? filter.yearMax : filter.yearEnd);
    const [versionSliderValue, setVersionSliderValue] = useState(filter.versionNumber < 0 ? 1 : filter.versionNumber);

    const [versionOption, setVersionOption] = useState(filter.versionOption);

    const loggedIn = useSelector(selectLoggedIn)

    let sliderYearStart = '';
    if(filter.yearSelection === 'yearStart') {
        sliderYearStart = <div>
            <Form.Label>{yearStartSliderValue}</Form.Label>
            <Form.Range 
                min={filter.yearMin}
                max={filter.yearMax}
                value={yearStartSliderValue}
                onChange={(e) => setYearStartSliderValue(e.target.value)}/>
        </div>
    }

    let sliderYearEnd = '';
    if(filter.yearSelection === 'yearEnd') {
        sliderYearEnd = <div>
            <Form.Label>{yearEndSliderValue}</Form.Label>
            <Form.Range 
                min={filter.yearMin}
                max={filter.yearMax}
                value={yearEndSliderValue}
                onChange={(e) => setYearEndSliderValue(e.target.value)}/>
        </div>
    }

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const filterModel = {
        driver: ['good', 'imperfect', 'preliminary'],
        emulation: ['good', 'imperfect', 'preliminary'],
        perimeter: ['parent', 'clone'],
        year: ['yearStart', 'yearEnd', 'yearUnavailable', 'yearUnsure'],
        misc: ['screenless', 'version']
    }

    if(loggedIn)
        filterModel.misc = ['favorite'].concat(filterModel.misc);
    
    function dropDownItemSuffix(key) {
        switch(key) {
            case 'yearStart':
                return ' ' + filter.yearStart;
            case 'yearEnd':
                return ' ' + filter.yearEnd;
            case 'version':
                return ': ' + t('versionSelectionModal.' + filter.versionOption) + ' ' + filter.versionNumber;
            default:
                return ' ✓';
        }
    }
    const items = Object.keys(filterModel).map(e => {
        const dropDownItems = filterModel[e].map(i => {
            return(
                <Dropdown.Item
                    key={`${e}-${i}`}
                    eventKey={`${e}-${i}`}
                    onClick={() => {
                        dispatch(filterSelected({selectedFilter: e, selectedValue: i}))
                    }}
                >
                    {t('misc.' + i) + (filter[e].includes(i) ? dropDownItemSuffix(i) : '')}
                </Dropdown.Item>
            )
        })
        const badge = filter[e].length > 0 ? <Badge text="dark" bg="light">{filter[e].length}</Badge> : '';

        return(
        <Col xs="auto">
        <Dropdown as={ButtonGroup} key={`dropdown-${e}`} id={`dropdown-${e}`} size="sm" style={{width: '100%'}}>
            <Dropdown.Toggle variant="secondary" id={`dropdown-toggle-${e}`}>
                {t('misc.' + e) + ' '}
                {badge}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {dropDownItems}
                <Dropdown.Divider />
                <Dropdown.Item key={`${e}-reset`} eventKey={`${e}-reset`} onClick={() => {dispatch(filterSelected({selectedFilter: e, selectedValue: 'reset'}))}}>Reset</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </Col>
        )
    })
    items.push(
        <Col xs="auto">
            <Dropdown 
                as={ButtonGroup} 
                key={`dropdown-category`} 
                id={`dropdown-category`} 
                size="sm" 
                style={{width: '100%'}}
            >
                <Dropdown.Toggle 
                    variant="secondary" 
                    id={`dropdown-toggle-category`}>
                    {t('misc.category') + ' '}
                    {filter.selectedCategories.length > 0 ? <Badge text="dark" bg="light">{filter.selectedCategories.length}</Badge> : ''}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        filter.selectedCategories.map(e => {
                            return(
                                <Dropdown.Item
                                    key={`category-${e.id}`}
                                    eventKey={`category-${e.id}`}
                                    onClick={() => {
                                        dispatch(filterSelected({selectedFilter: 'categoryItem', selectedValue: e.id}))
                                    }}
                                >
                                    {e.name + ' ✓'}
                                </Dropdown.Item>            
                            )
                        })
                    }
                    <Dropdown.Divider />
                    <Dropdown.Item
                        key={`category-categorySelection`}
                        eventKey={`category-categorySelection`}
                        onClick={() => {
                            dispatch(filterSelected({selectedFilter: 'category', selectedValue: 'categorySelection'}))
                        }}
                    >
                        {t('misc.categorySelection')}
                    </Dropdown.Item>
                    <Dropdown.Item 
                        key={`category-reset`}
                        eventKey={`category-reset`}
                        onClick={() => {dispatch(filterSelected({selectedFilter: 'category', selectedValue: 'reset'}))}}
                    >
                        Reset
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    )
    items.push(
        <Col>
            <FormControl type="text"
                    placeholder="Search"
                    id="search"
                    size='sm'
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={event => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            dispatch(filterSelected({
                                selectedFilter: 'textSearch',
                                selectedValue: searchText
                            }));
                        }
                    }}
                    value={searchText} />
        </Col>
    );
    items.push(
        <Col>
            <Button 
                size="sm"
                variant="secondary"
                onClick={() => dispatch(filterSelected({
                                            selectedFilter: 'textSearch',
                                            selectedValue: searchText
                                        })
                                )}>
                Search
            </Button>
        </Col>
    );
    
    items.push(<Col></Col>);

    // ↗ ↘ https://en.wikipedia.org/wiki/Arrow_(symbol)
    items.push(<Col className="d-flex justify-content-end">
        <Dropdown as={ButtonGroup} key={`dropdown-order`} id={`dropdown-order`} size="sm">
            <Dropdown.Toggle variant="secondary" id={`dropdown-toggle-order`}>
                {t('orderCol.' + orderCol) + (orderDir === 'asc' ? ' ↗' : ' ↘')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    ['name', 'description', 'year'].map((e) => {
                        return(
                            <Dropdown.Item
                                key={`order-${e}`}
                                eventKey={`order-${e}`}
                                onClick={() => {
                                    if(e !== orderCol)
                                        dispatch(orderSelected({
                                            orderCol: e,
                                            orderDir: orderDir
                                        }))
                                }}>
                                {t('orderCol.' + e) + (orderCol === e ? ' ✓' : '')}
                            </Dropdown.Item>
                        )
                    })
                }
                <Dropdown.Divider />
                {
                    ['asc', 'desc'].map((e) => {
                        return(
                            <Dropdown.Item 
                                key={`order-${e}`}
                                eventKey={`order-${e}`}
                                onClick={() => {
                                    if (e !== orderDir)
                                        dispatch(orderSelected({
                                            orderCol: orderCol,
                                            orderDir: e
                                        }))
                                }}>
                                {t('orderCol.' + e) + (orderDir === e ? ' ✓' : '')}
                            </Dropdown.Item>
                        )
                    })
                }                
            </Dropdown.Menu>
        </Dropdown>
    </Col>);

    return (
        <div>
            <Modal
                show={['yearStart', 'yearEnd'].includes(filter.yearSelection)}
                onHide={() => {dispatch(hideYearSelection())}}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>{t('yearSelectionModal.' + filter.yearSelection)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sliderYearStart}
                    {sliderYearEnd}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => {dispatch(hideYearSelection())}}>
                    {t('misc.cancel')}
                </Button>
                <Button
                    disabled={filter[filter.yearSelection] < 0}
                    variant="danger"
                    onClick={() => {dispatch(filterSelected({
                            selectedFilter: 'year',
                            selectedValue: -1
                        }))}
                    }>
                    {t('yearSelectionModal.remove')}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {dispatch(filterSelected({
                            selectedFilter: 'year',
                            selectedValue: filter.yearSelection === 'yearStart' ? yearStartSliderValue : yearEndSliderValue
                        }))}
                    }>
                    {t('misc.confirm')}
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={filter.versionSelection}
                onHide={() => {dispatch(hideVersionSelection())}}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>{t('versionSelectionModal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div>
                            <Form.Label>{versionSliderValue}</Form.Label>
                            <Form.Range 
                                min={1}
                                max={237}
                                value={versionSliderValue}
                                onChange={(e) => setVersionSliderValue(e.target.value)}/>
                        </div>
                        <div>
                            <Form.Check
                                inline
                                label={t('versionSelectionModal.included')}
                                name="group1"
                                type='radio'
                                id='inline-radio-1'
                                checked={versionOption === 'UPTO'}
                                onChange={(e) => {
                                    if(e.target.checked)
                                        setVersionOption('UPTO')
                                }}
                            />
                            <Form.Check
                                inline
                                label={t('versionSelectionModal.introduced')}
                                name="group1"
                                type='radio'
                                id='inline-radio-2'
                                checked={versionOption === 'EXACT'}
                                onChange={(e) => {
                                    if(e.target.checked)
                                        setVersionOption('EXACT')
                                }}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => {dispatch(hideVersionSelection())}}>
                    {t('misc.cancel')}
                </Button>
                <Button
                    disabled={filter[filter.versionSelection] < 0}
                    variant="danger"
                    onClick={() => {dispatch(filterSelected({
                            selectedFilter: 'misc',
                            selectedValue: 'version',
                            versionNumber: -1,
                        }))}
                    }>
                    {t('versionSelectionModal.remove')}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {dispatch(filterSelected({
                            selectedFilter: 'misc',
                            selectedValue: 'version',
                            versionNumber: versionSliderValue,
                            versionOption: versionOption
                        }))}
                    }>
                    {t('misc.confirm')}
                </Button>
                </Modal.Footer>
            </Modal>
            <CategorySelector />
            <Form className="align-items-start">
                <Row>
                    {items}
                </Row>
            </Form>
        </div>
    );
}