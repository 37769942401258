import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiManager } from '../apiManager'

export const auth = createAsyncThunk(
    'login/auth',
    async (formData) => {
        const api = {
            "name": "auth",
            "method": "post"
        }
        //const string = username + ':' + password;
        //const codeUnits = new Uint16Array(string.length);
        //for (let i = 0; i < codeUnits.length; i++) {
        //    codeUnits[i] = string.charCodeAt(i);
        //}
        //const b64 = btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
        const b64 = btoa(formData.username + ':' + formData.password);
        localStorage.setItem('username', formData.username)
        const r = {
            "variables": {},
            "data": {},
            "headers": {
                "Authorization": 'Basic ' + b64
            }
        }
        const response = await apiManager(api, r);
        return response;
    }
);

const initialState = {
    username: localStorage.getItem('username') || null,
    token: localStorage.getItem('token') || null,
    show: false,
    status: 'idle'
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        close: (state, action) => {
            state.show = false;
        },
        show: (state, action) => {
            state.show = true;
        },
        logout: (state, action) => {
            state.show = false;
            state.username = null;
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('username');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(auth.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(auth.fulfilled, (state, action) => {
                state.status = 'idle';
                state.token = action.payload.data;
                localStorage.setItem('token', action.payload.data)
                state.username = localStorage.getItem('username');
                state.show = false;
            })
            .addCase(auth.rejected, (state) => {
                state.status = 'idle';
            })
    },
});

export const { close, show, logout } = loginSlice.actions;
export const selectUsername = (state) => state.login.username;
export const selectShow = (state) => state.login.show;
export const selectStatus = (state) => state.login.status;
export const selectLoggedIn = (state) => state.login.token ? true : false;
export default loginSlice.reducer;