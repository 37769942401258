import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import { deleteFavorite, favoriteUpdateRequested, postFavorite, selectMachines } from '../../homeListSlice'
import { Link } from "react-router-dom";
import { reloadGameDetail } from '../../../gamedetail/gameDetailSlice';
import { machineSelected } from '../../../player/playerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad } from '@fortawesome/free-solid-svg-icons'
import GhostIcon from '../../../../component/GhostIcon';
import StatusIcon from '../../../../component/StatusIcon';
import BookmarkIcon from '../../../../component/BookmarkIcon';

export default function CardsRenderer() {

    const machines = useSelector(selectMachines);

    const dispatch = useDispatch();

    const handleBookmarkClick = (machineName, favorite) => {
        dispatch(favoriteUpdateRequested(machineName));
        let request = {
            variables: {name: machineName},
            data: {}
        }
        if(!favorite) {   
            dispatch(postFavorite(request));
        } else {
            dispatch(deleteFavorite(request))
        }
    }

    const cards = machines.map( (item, key) => 
        <Col id={`col-id-${item.name}`} key={item.name} md={3} className="d-flex py-2">
            <Card className="shadow-sm flex-fill">
                <Link to={"/gameDetail/" + item.name} 
                      onClick={() => {
                        window.sessionStorage.setItem('gameCardsVerticalPosition', window.scrollY);
                        dispatch(reloadGameDetail());
                      }}>
                    <img className="rounded mx-auto d-block p-2 imglink"
                         src={"https://mdb.dfri.info/snap/" + item.name + ".png"}
                         height="180" 
                         alt="Snapshot not available"
                         style={{"maxWidth": "100%"}}>
                    </img>
                </Link>
                <Card.Body>
                    <Card.Text>
                        {item.description}
                        <br />
                        {`${item.manufacturer} - ${item.year}`}
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="hstack gap-2 justify-content-end">
                    <div>{item.name}</div>
                    <div className="ms-auto"></div>
                    <BookmarkIcon favorite={item.favorite} machineName={item.name} handleClick={handleBookmarkClick} />
                    <StatusIcon machineName={item.name} status={item.driverStatus} keyContext='driver_status' />
                    <GhostIcon machineName={item.name} cloneOf={item.cloneOf} />
                    <FontAwesomeIcon 
                        onClick={() => {dispatch(machineSelected(item.name))}}
                        icon={faGamepad}
                        className='imglink'
                    />
                </Card.Footer>
            </Card>
        </Col>
    );

    return(
        <Row>{cards}</Row>
    )
}