import axios from 'axios';
import { basePath } from './conf';

export async function loadList(r) {
    let show = r.show || 'all';
    const orderBy = r.orderBy || 'name';
    const page = r.page || 1;
    const filter = r.filter ? `&filter=${r.filter}` : ``;
    const direction = '?direction=' + (r.direction || 'asc')
    const pageSize = r.pageSize || 64
    let customFilter = '';
    if(show === 'singleCategory' && r.selectedCategory) {
        customFilter += `&categories=${r.selectedCategory.scid}`;
        show = 'custom';
    } else if(show === 'custom' && r.customFilter) {
        let comma = '';
        if(r.customFilter.driverStatusGood || r.customFilter.driverStatusImperfect || r.customFilter.driverStatusPreliminary) {
            customFilter += '&driverStatus=';
            comma = r.customFilter.driverStatusImperfect || r.customFilter.driverStatusPreliminary ? ',' : ''
            customFilter += r.customFilter.driverStatusGood ? `good${comma}` : '';
            comma = r.customFilter.driverStatusPreliminary ? ',' : ''
            customFilter += r.customFilter.driverStatusImperfect ? `imperfect${comma}` : '';
            customFilter += r.customFilter.driverStatusPreliminary ? `preliminary` : '';
        }
        if(r.customFilter.emulationGood || r.customFilter.emulationImperfect || r.customFilter.emulationPreliminary) {
            customFilter += '&emulation=';
            comma = r.customFilter.emulationImperfect || r.customFilter.emulationPreliminary ? ',' : ''
            customFilter += r.customFilter.emulationGood ? `good${comma}` : '';
            comma = r.customFilter.emulationPreliminary ? ',' : ''
            customFilter += r.customFilter.emulationImperfect ? `imperfect${comma}` : '';
            customFilter += r.customFilter.emulationPreliminary ? `preliminary` : '';
        }
        if(r.customFilter.cloneOf && r.customFilter.cloneOf !== 'both') {
            customFilter += `&cloneOf=${r.customFilter.cloneOf}`;
        }
        if(r.customFilter.year && r.customFilter.year !== 'all') {
            customFilter += `&year=${r.customFilter.year}${r.customFilter.yearA}`;
            customFilter += r.customFilter.year === 'between' ? `and${r.customFilter.yearB}` : '';
        }
        if(r.customFilter.screenless) {
            customFilter += `&screenless=yes`;
        }
        if(r.customFilter.categories.length > 0) {
            const catstr = r.customFilter.categories.join();
            customFilter += `&categories=${catstr}`;
        }
    }
    const response = await axios.get(`${basePath}/games/list/show/${show}/orderby/${orderBy}${direction}${filter}&pageNumber=${page}&pageSize=${pageSize}${customFilter}`)
    return response;
}

export async function loadDetail(gameName) {
    const response = await axios.get(`${basePath}/games/detail/${gameName}`);
    return response;
}

export async function loadScreen(gameName) {
    const response = await axios.get(`${basePath}/display/game/${gameName}`);
    return response;
}

export async function loadRomset(r) {
    const gameName = r.gameName;
    const version = r.version;
    const response = await axios.get(`${basePath}/romset/game/${gameName}/version/${version}`);
    return response;
}

export async function loadRomsetUpdates(gameName) {
    const response = await axios.get(`${basePath}/romset/game/${gameName}/updates`);
    return response;
}

export async function runGameImpl(game, romof) {
    const data = {"name": game, "romof": romof}
    const response = await axios.post("http://localhost:8997/mrunner", data);
    return response;
}

export async function emuOutStream() {
    const response = await axios.get("http://localhost:8997/mrunner");
    return response;
}

export async function loadSystemCategories(gameName) {
    const response = await axios.get(`${basePath}/category/type/system/game/${gameName}`);
    return response;
}