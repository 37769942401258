import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Container, Row, Col } from 'react-bootstrap';
import { selectStatus, selectLoaded, selectMachines } from '../../homeListSlice'

export default function TableRenderer() {

    const status = useSelector(selectStatus);
    const loaded = useSelector(selectLoaded);
    const machines = useSelector(selectMachines);

    const dispatch = useDispatch();

    const rows = machines.map((m) => { return(
        <tr>
            <td>{m.name}</td>
            <td>{m.description}</td>
            <td>{m.year}</td>
            <td>{m.manufacturer}</td>
        </tr>
    )})
    return(
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Year</th>
                    <th>Manufacturer</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    )
}