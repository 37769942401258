import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sequence: 0,
    items: []
};

export const errorHandlerSlice = createSlice({
    name: 'errorHandler',
    initialState,
    reducers: {
        dismiss: (state, action) => {
            state.items.find(i => i.key === action.payload).visible = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    const error = action.error.message || "Generic error";
                    if (action.error.errorCode != null) {
                        console.log(action.error.errorCode);
                    }
                    const currentSequence = state.sequence;
                    state.sequence = currentSequence + 1;
                    state.items.push({
                        "message": error,
                        "key": currentSequence,
                        "visible": true
                    });
                }
            )
    },
});

export const { dismiss } = errorHandlerSlice.actions;
export const selectItems = (state) => state.errorHandler.items;
export default errorHandlerSlice.reducer;