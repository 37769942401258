import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Badge, Dropdown, ButtonGroup, Modal, Button, Form, FormControl, Row, Col, Container, ListGroup, Nav } from 'react-bootstrap';
import { selectFilter, selectCategoryList, selectCategoryListStatus, selectCategoryListLoaded, hideCategorySelection, filterSelected } from './homeListSlice'
import { useTranslation } from 'react-i18next';
import Stack from 'react-bootstrap/Stack';
import { faFolder, faTag, faAngleRight, faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadCategories } from './homeListSlice';

export default function CategorySelector() {
    
    const status = useSelector(selectCategoryListStatus);
    const loaded = useSelector(selectCategoryListLoaded);
    const categoryList = useSelector(selectCategoryList);
    const [selection, setSelection] = useState([]);
    const filter = useSelector(selectFilter);
    const [taggedItems, setTaggedItems] = useState([]);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (status === 'idle' && !loaded) {
            let request = {
                variables: {},
                data: {}
            }
            dispatch(loadCategories(request));
        }
        //setTaggedItems(taggedItems.concat(filter.category.filter(e => !taggedItems.some(k => k.id === e.id))));
    });
    
    useEffect(() => {
        setTaggedItems(taggedItems.concat(filter.selectedCategories.filter(e => !taggedItems.some(k => k.id === e.id))));
    }, [filter.selectedCategories]);

    const handleSelection = (id, name, leaf, level) => {
        const tmp = selection.slice(0, level);
        tmp.push(id);
        setSelection(tmp);
        if (leaf && !taggedItems.some((e) => {return e.id === id}))
            setTaggedItems(taggedItems.concat([{id: id, name: name}]));
    }

    const buildCol = (rows, level) => {
        const col = rows.map(e => {
            const selected = level < selection.length && selection[level] === e.id;
            return(
                <div className={selected ? 'categoryNavigatorSelectedItem' : 'categoryNavigatorUnselectedItem'}>
                    <Stack direction='horizontal'>
                        <div>
                            <FontAwesomeIcon 
                                icon={e.childs.length > 0 ? faFolder : faFile}
                                color={e.childs.length > 0 ? "#88BAFF" : "grey"}
                            />
                        </div>
                        <div
                            style={{"marginLeft": "5px"}}
                            className="imglink"
                            onClick={() => {handleSelection(e.id, e.name, e.childs.length === 0, level)}}
                        >
                            {e.name}
                        </div>
                        <div className='ms-auto me-1'>
                            <FontAwesomeIcon icon={e.childs.length > 0 ? faAngleRight : null} color="grey" />
                        </div>
                    </Stack>
                </div>
            );
        })
        return col;
    }

    const extractColData = (rows, level) => {
        const col = rows.map(e => { return {id: e.id, childs: e.childs}; })
        return col;
    }

    function Loading() {
        return (
            <div className="py-1">
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            </div>
        )
    }

    let cols = [buildCol(categoryList.data, 0)];
    let colsData = [extractColData(categoryList.data)];
    selection.forEach((element, index) => {
        const selected = colsData[index].find(e => e.id === element);
        if (selected.childs.length > 0) {
            cols.push(buildCol(selected.childs, index + 1));
            colsData.push(extractColData(selected.childs));
        }
    })
    const categories = !loaded ? <Loading /> : cols.map(e => { return <div class="categoryNavigatorItemList">{e}</div>})
    const tags = taggedItems.map(e => {return (
        <div className='categoryNavigatorTaggedItem'>
            <span className='ms-1 me-1'>{e.name}</span>
            <FontAwesomeIcon 
                icon={faTag}
                className="categoryNavigatorTagIcon" 
                onClick={() => {setTaggedItems(taggedItems.filter(k => k.id !== e.id))}}
            />
        </div>
    )})

    const handleConfirm = () => {
        dispatch(filterSelected({
            selectedFilter: 'category',
            selectedValue: taggedItems
        }))
    }

    return(
        <Modal
            size="lg"
            show={filter.categorySelection}
            onHide={() => dispatch(hideCategorySelection())}
            aria-labelledby="category-selector-modal"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title id="category-selector-modal">
                {t('categorySelector.title') + ' '}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{"padding": "0px"}}>
                <div>
                    <div className='categoryNavigatorTagsContainer'>
                        {tags}
                    </div>
                    <div class="categoryNavigatorContainer">
                        {categories}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => dispatch(hideCategorySelection())}>
                    {t('categorySelector.close') + ' '}
                </Button>
                <Button variant="primary" onClick={() => handleConfirm()}>{t('categorySelector.confirm') + ' '}</Button>
            </Modal.Footer>
        </Modal>
        
    );
}