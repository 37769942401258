import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { 
    selectData,
    selectShow,
    selectMachine,
    runMachine,
    close,
    selectRunning,
    runStatus
} from './playerSlice';
import { Terminal } from 'xterm';

export default function Player() {
    const data = useSelector(selectData);
    const show = useSelector(selectShow);
    const machine = useSelector(selectMachine);
    const running = useSelector(selectRunning);

    const elRef = useRef(null);
    const terminal = useRef(null);

    const dispatch = useDispatch();

    const handleClose = () => dispatch(close());

    useEffect(() => {
        if(machine != null) {
            dispatch(runMachine(machine));
            if(terminal.current != null) {
                terminal.current.dispose();
                terminal.current = null;
            }
        }
    },[machine]);

    useEffect(() => {
        if(terminal.current != null) {
            data.forEach(e => {
                terminal.current.write(`${e}\r\n`);
            })
        }
    },[data]);

    useEffect(() => {
        if (elRef && elRef.current) {
            if (terminal.current === null) {
                const term = new Terminal({
                    convertEol: true,
                    fontFamily: `'Fira Mono', monospace`,
                    fontSize: 15,
                    fontWeight: 900
                    // rendererType: "dom" // default is canvas
                });
                terminal.current = term;
                data.forEach(e => {
                    term.write(`${e}\r\n`);
                })
                term.open(elRef.current);
            }
        }
    });

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    useInterval(() => {
        if(running) {
            dispatch(runStatus());
        }
    }, 1000);

    let rows = [];
    data.forEach(e => {
        rows.push(e);
        rows.push(<br/>);
    })
    return(
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='lg'>
        <Modal.Header className='terminalTitle' closeButton>
          <Modal.Title>Running: {machine}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='terminalBody'>
            <div ref={elRef}></div>
        </Modal.Body>
      </Modal>
    );
}