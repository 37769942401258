import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./features/about/About";
import Settings from "./features/settings/Settings";
import GameDetail from './features/gamedetail/GameDetail';
import HomeList from "./features/homeList/HomeList";
import Header from './features/header/Header';
import ErrorHandler from './features/errorHandler/ErrorHandler';
import PlayGame from './features/play/PlayGame';
import Player from './features/player/Player';
import './App.css';

function App() {

  return (
    <Router>
      <Header />
      <ErrorHandler />
      <Player />
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/gameDetail/:game" component={GameDetail}/>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route exact path="/">
          <HomeList />
        </Route>
        <Route path="/play/:game" component={PlayGame}/>
      </Switch>
    </Router>
  );
}

export default App;
