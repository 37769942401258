import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from 'react-bootstrap';
import { selectTotal, selectPage, selectPageSize, selectPaginatorSize, pageSelected } from './homeListSlice'
import paginatorConfigurator from '../../hooks/paginatorConfigurator';

export default function Paginator() {
    const total = useSelector(selectTotal);
    const page = useSelector(selectPage);
    const pageSize = useSelector(selectPageSize);
    const paginatorSize = useSelector(selectPaginatorSize);

    const dispatch = useDispatch();
    
    const paginatorConfiguration = paginatorConfigurator(page, paginatorSize, total, pageSize);

    const tail = paginatorConfiguration.tail;
    const head = paginatorConfiguration.head;
    const pages = paginatorConfiguration.pages;

    let paginatorItems = [];
    if(tail > 1) {
        paginatorItems.push(
            <Pagination.Item key={`game-list-page-1`} 
                    active={page === 1}
                    onClick={() => dispatch(pageSelected(1))}>
                1
            </Pagination.Item>
        );
        paginatorItems.push(
            <Pagination.Ellipsis key='game-list-ellipsis-start' />
        );
    }
    for(let i = parseInt(tail); i <= parseInt(head); i++) {
        paginatorItems.push(
            <Pagination.Item key={`game-list-page-${i}`} 
                    active={page === i}
                    onClick={() => dispatch(pageSelected(i))}>
                {i}
            </Pagination.Item>
        );
    }
    if(head < pages) {
        paginatorItems.push(
            <Pagination.Ellipsis key='game-list-ellipsis-end'/>
        );
        paginatorItems.push(
            <Pagination.Item key={`game-list-page-${pages}`} 
                    active={page === pages}
                    onClick={() => dispatch(pageSelected(pages))}>
                {pages}
            </Pagination.Item>
        );
    }

    return(
        <div className="d-flex justify-content-center">
            <Pagination>
                {paginatorItems}
            </Pagination>
        </div>
    )
}