import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { selectLoggedIn } from '../features/login/loginSlice';
import { useSelector } from 'react-redux';

export default function BookmarkIcon({favorite, machineName, handleClick}) {
    const loggedIn = useSelector(selectLoggedIn)
    if(loggedIn) {
        return (
            <FontAwesomeIcon 
                icon={faBookmark} 
                className={favorite ? 'favitem' : 'unfavitem'}
                onClick={() => {handleClick(machineName, favorite)}}
            />
        );
    } else {
        return <span id={`${machineName}-favorite-placeholder`} />
    }
}