import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGhost } from '@fortawesome/free-solid-svg-icons'

export default function GhostIcon({machineName, cloneOf}) {
    if(cloneOf != null) {
        return (
            <OverlayTrigger key={`${machineName}-otk-cloneof`}
                        placement={'top'}
                        overlay={
                            <Tooltip id={`${machineName}-tooltip-cloneof`}>
                                Clone of {` ${cloneOf}`}
                            </Tooltip>
                        }>
                <div>
                    <FontAwesomeIcon icon={faGhost} />
                </div>
            </OverlayTrigger>
        );
    } else {
        return '';
    }
}