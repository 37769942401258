import { configureStore } from '@reduxjs/toolkit';
import gameListReducer from '../features/gameList/gameListSlice';
import gameDetailSlice from '../features/gamedetail/gameDetailSlice';
import errorHandlerSlice from '../features/errorHandler/errorHandlerSlice';
import homeListSlice from '../features/homeList/homeListSlice';
import playerSlice from '../features/player/playerSlice';
import loginSlice from '../features/login/loginSlice';

export const store = configureStore({
  reducer: {
    gameList: gameListReducer,
    gameDetail: gameDetailSlice,
    errorHandler: errorHandlerSlice,
    homeList: homeListSlice,
    player: playerSlice,
    login: loginSlice,
  },
});
