import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postCategory, getSysCategory } from '../../api/category';
import { loadList, runGameImpl } from '../../api/game';

export const loadGames = createAsyncThunk(
    'gameList/loadGames',
    async (r) => {
        const response = await loadList(r);
        return response;
    }
);

export const loadPaginatedGames = createAsyncThunk(
    'gameList/loadPaginatedGames',
    async (r) => {
        const response = await loadList(r);
        return response;
    }
);

export const updateFavourite = createAsyncThunk(
    'game/updateFavourite',
    async (r) => {
        const response = await postCategory({...r, category: 'fav'});
        return response;
    }
);

export const loadSysCategories = createAsyncThunk(
    'category/loadSystemCategories',
    async (r) => {
        const response = await getSysCategory({...r, category: 'fav'});
        return response;
    }
);

export const runGame = createAsyncThunk(
    'game/run',
    async (r) => {
        const response = await runGameImpl(r.name, r.romof);
        return response;
    }
);

const toggleSelectedField = (tree, scid, i = 0) => {
    if(i < tree.length) {
        if(tree[i].scid == scid) {
            tree[i].selected = !tree[i].selected;
            return true;
        } else {
            if(!toggleSelectedField(tree[i].childs, scid)) {
                return toggleSelectedField(tree, scid, i + 1);
            }
            return false;
        }
    } else {
        return false;
    }
}

const differ = (submitted, selected) => {
    const submittedSize = submitted.length;
    const selectedSize = selected.length;
    if(submittedSize !== selectedSize) {
        return true;
    } else {
        let flag = false;
        selected.forEach(e => {
            const byScid = (o) => o.scid === e.scid;
            flag = flag || submitted.findIndex(byScid) < 0;
        });
        return flag;
    }
}

const initialState = {
    games: [],
    show: 'all',
    orderBy: 'name',
    direction: 'asc',
    filter: '',
    hasMore: null,
    page: 1,
    pageSize: 40,
    total: 0,
    loaded: false,
    loadingMore: false,
    status: 'idle',
    verticalScrollTo: '',
    computingSizeIndex: null,
    customFilter: {
        show: false,
        active: false,
        driverStatusGood: false,
        driverStatusImperfect: false,
        driverStatusPreliminary: false,
        emulationGood: false,
        emulationImperfect: false,
        emulationPreliminary: false,
        cloneOf: 'both',
        year: 'all',
        yearA: '',
        yearB: '',
        screenless: false,
        categories: [],
    },
    systemCategories: {
        status: 'idle',
        loaded: false,
        data: [],
        tree: [],
        selected: [],
        submitable: false,
    },
    top: null,
    bottom: null,
    selectedCategory: {
        scid: null,
        name: ''
    },
};

export const gameListSlice = createSlice({
    name: 'gameList',
    initialState,
    reducers: {
        reload: (state, action) => {
            let update = false;
            if(action.payload.customFilter) {
                update = true;
                state.customFilter = action.payload.customFilter;
                state.show = 'custom';
                state.systemCategories.submitable = false;
            } else if(action.payload.singleCategory) {
                update = true;
                state.show = 'singleCategory';
                state.selectedCategory = action.payload.singleCategory;
            } else {
                for (const property in action.payload) {
                    if(state[property] !== action.payload[property]) {
                        state[property] = action.payload[property];
                        update = true;
                    }
                }
            }
            if(update) {
                state.games = [];
                state.total = 0;
                state.hasMore = null;
                state.page = 1;
                state.loaded = false;
                state.loadingMore = false;
            }
        },
        loadMore: (state, action) => {
            state.loadingMore = true;
        },
        showCustomFilter: (state, action) => {
            state.customFilter.show = action.payload;
        },
        favUpdateEnd: (state, action) => {
            const i = parseInt(action.payload);
            state.games[i].favUpdating = 'end';
        },
        pageScrolled: (state, action) => {
            const prevTop = state.top || action.payload.top;
            const prevBottom = state.bottom || action.payload.bottom;
            if(prevTop > action.payload.top && prevBottom > action.payload.bottom) {
                console.log(`scroll event (DOWN): ${action.payload.top}, ${action.payload.bottom} | page ${state.page}`);
            } else if(prevTop < action.payload.top && prevBottom < action.payload.bottom) {
                console.log(`scroll event (UP): ${action.payload.top}, ${action.payload.bottom} | page ${state.page}`);
            } else {
                console.log(`scroll event (???): ${action.payload.top}, ${action.payload.bottom}`);
            }
            state.top = action.payload.top;
            state.bottom = action.payload.bottom;
        },
        pageSelected: (state, action) => {
            const requestedPage = parseInt(action.payload);
            if(requestedPage !== state.page) {
                state.page = requestedPage;
                state.loadingMore = true;
                state.verticalScrollTo = 'top';
            }
        },
        verticalScrollDone: (state) => {
            state.verticalScrollTo = '';
        },
        sizesComputed: (state, action) => {
            const sizes = action.payload;
            let j = 0;
            for(let i = state.computingSizeIndex; i < state.games.length; i++) {
                state.games[i].top = sizes[j].top;
                state.games[i].height = sizes[j].height;
                j++;
            }
        },
        systemCategorySelected: (state, action) => {
            toggleSelectedField(state.systemCategories.tree, action.payload.scid);
            const byScid = (element) => element.scid === action.payload.scid;
            const i = state.systemCategories.selected.findIndex(byScid);
            if(i >= 0) {
                state.systemCategories.selected.splice(i, 1);
            } else {
                state.systemCategories.selected.push(state.systemCategories.data.find(byScid));
            }
            state.systemCategories.submitable = differ(state.customFilter.categories, state.systemCategories.selected);
        },
        systemCategoryDeselected: (state, action) => {
            toggleSelectedField(state.systemCategories.tree, action.payload.scid);
            const byScid = (element) => element.scid === action.payload.scid;
            const i = state.systemCategories.selected.findIndex(byScid);
            if(i >= 0) {
                state.systemCategories.selected.splice(i, 1);
            }
            state.systemCategories.submitable = differ(state.customFilter.categories, state.systemCategories.selected);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateFavourite.fulfilled, (state, action) => {
                const gameSelector = (element) => element.name === action.payload.data.game;
                const i = state.games.findIndex(gameSelector);
                if(i >= 0) {
                    state.games[i].fav = action.payload.data.included;
                    state.games[i].favUpdating = 'completed';
                }
            })
            .addCase(loadGames.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadGames.fulfilled, (state, action) => {
                state.status = 'idle';
                state.loaded = true;
                state.loadingMore = false;
                let counter = state.games.length;
                state.computingSizeIndex = state.games.length;
                state.games = state.games.concat(action.payload.data.games.map((g) => { return {
                    ...g,
                    'i': counter++,
                    'top': null,
                    'height': null
                }}));
                state.page += 1;
                state.hasMore = (state.pageSize * state.page) <= action.payload.data.total;
                state.total = action.payload.data.total;
            })
            .addCase(loadPaginatedGames.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadPaginatedGames.fulfilled, (state, action) => {
                state.status = 'idle';
                state.loaded = true;
                state.loadingMore = false;
                state.games = [];
                let counter = 0;
                state.games = action.payload.data.games.map( (g) => { return {...g, 'i': counter++} } );
                state.total = action.payload.data.total;
            })
            .addCase(loadSysCategories.pending, (state) => {
                state.systemCategories.status = 'loading';
            })
            .addCase(loadSysCategories.fulfilled, (state, action) => {
                state.systemCategories.status = 'idle';
                state.systemCategories.loaded = true;
                state.systemCategories.data = action.payload.data;

                state.systemCategories.tree = [];
                
                function connectElements(elementList, element, maxLv) {
                    let tmp = [];
                    function filterPredicate(e) {
                        const targetLevel = element.level + 1;
                        return e.parent_id === element.scid && e.level === targetLevel;
                    }
                    elementList.filter(filterPredicate).forEach(e => {
                        tmp.push({...e, 'selected': false});
                    });
                    if(element.level < maxLv) {
                        tmp.forEach(e => e.childs = connectElements(elementList, e, maxLv))
                    }
                    return tmp;
                } 
                action.payload.data.filter(e => e.level === 0).forEach(e => {
                    const maxLv = Math.max.apply(Math, action.payload.data.map(function(o) { return o.level; }));
                    const obj = {
                        ...e,
                        'selected': false, 
                        'childs': connectElements(action.payload.data, e, maxLv)
                    };
                    state.systemCategories.tree.push(obj);
                });
                
            });
    },
});

export const { 
    reload,
    loadMore,
    showCustomFilter,
    favUpdateEnd,
    pageScrolled,
    pageSelected,
    verticalScrollDone,
    sizesComputed,
    systemCategorySelected,
    systemCategoryDeselected
} = gameListSlice.actions;
export const selectGames = (state) => state.gameList.games;
export const selectTotal = (state) => state.gameList.total;
export const selectLoaded = (state) => state.gameList.loaded;
export const selectShow = (state) => state.gameList.show;
export const selectSelectedCategory = (state) => state.gameList.selectedCategory;
export const selectOrderBy = (state) => state.gameList.orderBy;
export const selectDirection = (state) => state.gameList.direction;
export const selectFilter = (state) => state.gameList.filter;
export const selectStatus = (state) => state.gameList.status;
export const selectPage = (state) => state.gameList.page;
export const selectPageSize = (state) => state.gameList.pageSize;
export const selectHasMore = (state) => state.gameList.hasMore;
export const selectLoadingMore = (state) => state.gameList.loadingMore;
export const selectCustomFilter = (state) => state.gameList.customFilter;
export const selectVerticalScrollTo = (state) => state.gameList.verticalScrollTo;
export const selectComputingSizeIndex = (state) => state.gameList.computingSizeIndex;
export const selectSystemCategories = (state) => state.gameList.systemCategories;
export default gameListSlice.reducer;