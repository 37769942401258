import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Spinner, Nav, NavDropdown } from 'react-bootstrap';
import { loadMachines, selectStatus, selectLoaded, selectRenderer, selectPage, selectPageSize, selectFilter, selectOrderCol, selectOrderDir, selectTotal, rendererSelected } from './homeListSlice'
import TableRenderer from './renderers/table/TableRenderer';
import CardsRenderer from './renderers/cards/CardsRenderer';
import Paginator from './Paginator';
import FilterPanel from './FilterPanel';
import { useTranslation } from 'react-i18next';

export default function HomeList() {
    const renderer = useSelector(selectRenderer);
    const status = useSelector(selectStatus);
    const loaded = useSelector(selectLoaded);
    const page = useSelector(selectPage);
    const pageSize = useSelector(selectPageSize);
    const filter = useSelector(selectFilter);
    const total = useSelector(selectTotal)
    const orderCol = useSelector(selectOrderCol);
    const orderDir = useSelector(selectOrderDir);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const mapMiscfilter = (e, filter, request) => {
        switch (e) {
            case 'version':
                request.data.version = filter.versionNumber;
                request.data.versionOption = filter.versionOption;
                break;
            default:
                request.data[e] = true;
        }
    }

    useEffect(() => {
        if (status === 'idle' && !loaded) {
            let request = {
                variables: {},
                data: {
                    year: {},
                    pageNumber: page - 1,
                    pageSize: pageSize,
                    orderCol: orderCol.toUpperCase(),
                    orderDirection: orderDir.toUpperCase(),
                }
            }
            if (filter.driver.length > 0)
                request.data.driverStatus = filter.driver.map(x => x.toUpperCase());
            if (filter.emulation.length > 0)
                request.data.driverEmulation = filter.emulation.map(x => x.toUpperCase());
            if (filter.perimeter.length === 1)
                request.data.perimeter = filter.perimeter[0].toUpperCase();
            if (filter.yearStart > 0)
                request.data.year.start = filter.yearStart;
            if (filter.yearEnd > 0)
                request.data.year.end = filter.yearEnd;
            if (filter.year.includes('yearUnavailable'))
                request.data.year.unavailable = true;
            if (filter.year.includes('yearUnsure'))
                request.data.year.unsure = true;
            filter.misc.map(e => mapMiscfilter(e, filter, request));
            if (filter.filter !== '')
                request.data.filter = filter.filter;
            if (filter.selectedCategories.length > 0)
                request.data.category = filter.selectedCategories.map(e => e.id);
            dispatch(loadMachines(request));
        }
    });

    function Loading() {
        return (
            <div className="py-1">
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            </div>
        )
    }

    let rendererComponent;
    switch (renderer) {
        case 'cards':
            rendererComponent = <CardsRenderer />
            break;
        case 'table':
        default:
            rendererComponent = <TableRenderer></TableRenderer>
    }
    
    const handleSelect = (eventKey) => dispatch(rendererSelected(eventKey));

    const viewerSelector = (
        <Nav variant="pills" activeKey={renderer} onSelect={handleSelect} style={{"display": "inline-block"}}>
            <NavDropdown title={t('statusMessage.' + renderer)} id="viewer-selector-dropdown">
                <NavDropdown.Item eventKey="cards">{t('statusMessage.cards')}</NavDropdown.Item>
                <NavDropdown.Item eventKey="table">{t('statusMessage.table')}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

    if (status === 'loading' && !loaded) {
        return <Loading />
    } else {
        return(
            <Container>
                <Row className='mt-2 mb-2' />
                <Row>
                    <Col sm={12}>
                        <FilterPanel />
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col sm={12}>
                        <p>
                            {t('statusMessage.totalTitles') + ' ' + total}
                            {t('statusMessage.separator')}
                            {pageSize + ' ' + t('statusMessage.titlesPerPage')}
                            {t('statusMessage.separator')}
                            {t('statusMessage.currentPage') + ' ' + page}
                            {t('statusMessage.separator')}
                            {t('statusMessage.totalPages') + ' ' + Math.ceil(total / pageSize)}
                            {t('statusMessage.separator')}
                            {t('statusMessage.viewAs') + ' '} {viewerSelector}
                        </p>
                    </Col>
                </Row>
                
                <Row>
                    <Col sm={12}>
                        {rendererComponent}
                    </Col>
                </Row>
                <Row>
                    <Paginator />
                </Row>
            </Container>
        )
    }
}