import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Container } from 'react-bootstrap';
import { selectItems, dismiss } from './errorHandlerSlice'

export default function ErrorHandler() {

    const items = useSelector(selectItems);
    
    const dispatch = useDispatch();

    const alerts = items.filter(i => i.visible === true).map((item) => {
        return(
            <Alert 
                variant="danger"
                className='mt-1 mb-1'
                key={item.key}
                onClose={() => dispatch(dismiss(item.key))}
                dismissible>
                {item.message}
            </Alert>
        )
    })
    return <Container>{alerts}</Container>;
}