import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          header: {
            name: "Name",
            filter: "Filter"
          },
          misc: {
            filter: "Filter",
            good: "Good",
            imperfect: "Imperfect",
            preliminary: "Preliminary",
            driver: "Driver",
            emulation: "Emulation",
            perimeter: "Perimeter",
            parent: "Parent",
            clone: "Clone",
            year: "Year",
            yearStart: "From",
            yearEnd: "Up to",
            yearUnavailable: "Unavailable",
            yearUnsure: "Not confirmed",
            cancel: "Cancel",
            confirm: "Confirm",
            misc: "Misc",
            favorite: "Favorite",
            screenless: "Screenless",
            category: "Category",
            categorySelection: "Select...",
            version: "Version"
          },
          yearSelectionModal: {
            yearStart: "Year from",
            yearEnd: "Year up to",
            remove: "Clear filter"
          },
          versionSelectionModal: {
            title: "Filter version",
            remove: "Clear filter",
            UPTO: "included in",
            EXACT: "introduced in",
            included: "Included",
            introduced: "Introduced"
          },
          orderCol: {
            name: "Name",
            description: "Description",
            year: "Year",
            asc: "Direction up",
            desc: "Direction down"
          },
          statusIcon: {
            driver_status: "Driver status is"
          },
          categorySelector: {
            close: "Close",
            confirm: "Ok",
            title: "Category Selector"
          },
          statusMessage: {
            totalTitles: "Total number of titles",
            titlesPerPage: "Titles per page",
            currentPage: "Current page",
            totalPages: "Total pages",
            separator: " | ",
            viewAs: "View as",
            table: "table",
            cards: "cards"
          }
        }
      },
      it: {
        translation: {
            header: {
              name: "Nome",
              filter: "Filtro"
            },
            misc: {
              filter: "Filtro",
              good: "Buono",
              imperfect: "Impreciso",
              preliminary: "Preliminare",
              driver: "Driver",
              emulation: "Emulazione",
              perimeter: "Perimetro",
              parent: "Originale",
              clone: "Clone",
              year: "Anno",
              yearStart: "A partire da",
              yearEnd: "Fino a",
              yearUnavailable: "Non disponibile",
              yearUnsure: "Non confermato",
              cancel: "Annulla",
              confirm: "Conferma",
              misc: "Vari",
              favorite: "Preferiti",
              screenless: "Privi di schermo",
              category: "Categoria",
              categorySelection: "Seleziona...",
              version: "Versione"
            },
            yearSelectionModal: {
              yearStart: "Anno a partire da",
              yearEnd: "Anno fino a",
              remove: "Rimuovi filtro"
            },
            versionSelectionModal: {
              title: "Filtra versione",
              remove: "Rimuovi filtro",
              UPTO: "compresi nella",
              EXACT: "introdotti con la",
              included: "Inclusi",
              introduced: "Introdotti"
            },
            orderCol: {
              name: "Nome breve",
              description: "Nome esteso",
              year: "Anno",
              asc: "Crescente",
              desc: "Decrescente"
            },
            statusIcon: {
              driver_status: "Il driver è"
            },
            categorySelector: {
              close: "Annulla",
              confirm: "Conferma",
              title: "Categorie"
            },
            statusMessage: {
              totalTitles: "Numero totale di titoli",
              titlesPerPage: "titoli per pagina",
              currentPage: "Pagina corrente",
              totalPages: "Pagine totali",
              separator: " | ",
              viewAs: "Visualizza come",
              table: "tabella",
              cards: "mosaico"
            }
          }
      }
    }
  });

export default i18n;