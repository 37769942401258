import React, { useRef, useEffect, useState } from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';
import HomeHeader from './HomeHeader';
import DefaultHeader from './DefaultHeader';
import { selectLoggedIn, selectUsername, selectShow, close, show, auth, logout } from '../login/loginSlice';

export default function Header() {

    const navbar = useRef(null);

    const location = useLocation();

    const loggedIn = useSelector(selectLoggedIn);
    const username = useSelector(selectUsername);
    const showLoginModal = useSelector(selectShow);

    const dispatch = useDispatch();

    useEffect(() => {
        document.body.style = "padding-top: " + navbar.current.clientHeight + "px;";
    });

    function PageHeader(props) {
        switch (props.path) {
            case '/':
                return <HomeHeader/>;
            default:
                return <DefaultHeader/>;
        }
    }

    function LoginModal(props) {

        const [loginUsername, setLoginUsername] = useState(props.username);
        const [loginPassword, setLoginPassword] = useState('');

        return(
            <Modal
                show={props.show}
                onHide={() => {dispatch(close())}}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter username"
                            value={loginUsername}
                            onChange={(e) => {setLoginUsername(e.target.value)}}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={loginPassword}
                            onChange={(e) => {setLoginPassword(e.target.value)}}
                        />
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {dispatch(close())}}>
                        Close
                    </Button>
                    <Button 
                        variant="primary"
                        onClick={() => {dispatch(auth({username: loginUsername, password: loginPassword}))}}
                    >
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return(
        <div>
            <LoginModal show={showLoginModal} username={username} />
            <Navbar bg="dark" variant="dark" fixed="top" expand="lg" ref={navbar}>
                <Container>
                    <Navbar.Brand>
                        <Link to={"/"} className='navBarLink'>
                            <strong>Mame Database</strong>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <PageHeader path={location.pathname}/>
                    </Navbar.Collapse>
                    <NavDropdown
                        id="nav-dropdown-login"
                        title={loggedIn ? username : 'Guest'}
                        menuVariant="dark"
                    >
                        <NavDropdown.Item 
                            onClick={() => {loggedIn ? dispatch(logout()) : dispatch(show())}}
                        >
                            {loggedIn ? 'logout' : 'login'}
                        </NavDropdown.Item>
                    </NavDropdown>
                </Container>
            </Navbar>
        </div>
    );
}