import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiManager } from '../apiManager'

export const loadMachines = createAsyncThunk(
    'homeList/loadMachines',
    async (r) => {
        const api = {
            "name": "machineList",
            "method": "post"
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const loadCategories = createAsyncThunk(
    'homeList/loadCategories',
    async (r) => {
        const api = {
            "name": "categoryStructure",
            "method": "get"
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const postFavorite = createAsyncThunk(
    'homeList/postFavorite',
    async (r) => {
        const api = {
            "name": "favorite",
            "method": "post"
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const deleteFavorite = createAsyncThunk(
    'homeList/deleteFavorite',
    async (r) => {
        const api = {
            "name": "favorite",
            "method": "delete"
        }
        const response = await apiManager(api, r);
        return response;
    }
);

const initialState = {
    renderer: "cards",
    machines: [],
    total: 0,
    page: 1,
    pageSize: 40,
    paginatorSize: 20,
    loaded: false,
    status: 'idle',
    categoryListLoaded: false,
    categoryListStatus: 'idle',
    filter: {
        yearSelection: '', // trigger year selection modal
        categorySelection: false, // trigger category selection modal
        versionSelection: false, // trigger version selection modal
        driver: [],
        emulation: [],
        perimeter: [],
        year: [],
        yearMin: 1948,
        yearMax: 2020,
        yearStart: -1,
        yearEnd: -1,
        versionNumber: -1,
        versionOption: "UPTO",
        misc: [],
        selectedCategories: [],
        filter: ''
    },
    categoryList: {
        data: []
    },
    favoriteUpdate: null,
    orderCol: 'name',
    orderDir: 'asc',
};
export const homeListSlice = createSlice({
    name: 'homeList',
    initialState,
    reducers: {
        pageSelected: (state, action) => {
            const requestedPage = parseInt(action.payload);
            if(requestedPage !== state.page) {
                state.page = requestedPage;
                state.loaded = false;
                //state.verticalScrollTo = 'top';
            }
        },
        rendererSelected: (state, action) => {
            if(state.renderer !== action.payload)
                state.renderer = action.payload;
        },
        filterSelected: (state, action) => {
            const {selectedFilter, selectedValue} = action.payload;
            state.page = 1;
            switch (selectedFilter) {
                case 'driver':
                case 'emulation':
                    if(selectedValue === 'reset') {
                        state.filter[selectedFilter] = [];
                    } else if(state.filter[selectedFilter].includes(selectedValue)) {
                        state.filter[selectedFilter] = state.filter[selectedFilter].filter(e => e !== selectedValue);
                    } else {
                        state.filter[selectedFilter].push(selectedValue);
                    }
                    state.loaded = false;
                    break;
                case 'perimeter':
                    state.filter[selectedFilter] = selectedValue === 'reset' ? [] : [selectedValue];
                    state.loaded = false;
                    break;
                case 'misc':
                    if(selectedValue === 'reset') {
                        state.filter[selectedFilter] = [];
                        state.filter.versionNumber = -1;
                        state.loaded = false;
                    } else if(selectedValue === 'version') {
                        if(state.filter.versionSelection === true) {
                            state.filter.versionSelection = false;
                            const versionNumber = action.payload.versionNumber || -1;
                            state.filter.versionNumber = versionNumber;
                            state.filter.versionOption = action.payload.versionOption || 'UPTO';
                            if(versionNumber === -1) {
                                state.filter.misc = state.filter.misc.filter(e => e !== selectedValue);
                            } else if(!state.filter.misc.includes(selectedValue)) {
                                state.filter.misc.push(selectedValue);
                            }
                            state.loaded = false;
                        } else {
                            state.filter.versionSelection = true;
                        }
                    } else if(state.filter.misc.includes(selectedValue)) {
                        state.filter.misc = state.filter.misc.filter(e => e !== selectedValue);
                        state.loaded = false;
                    } else {
                        state.filter.misc.push(selectedValue);
                        state.loaded = false;
                    }
                    break;
                case 'year':
                    if(selectedValue === 'yearStart' || selectedValue === 'yearEnd') {
                        state.filter.yearSelection = selectedValue;
                    } else if(!isNaN(Number.parseInt(selectedValue))) {
                        state.filter.year = state.filter.year.filter(e => ['yearStart', 'yearEnd'].includes(e));
                        state.filter[state.filter.yearSelection] = Number.parseInt(selectedValue);
                        if(state.filter[state.filter.yearSelection] < 0)
                            state.filter.year = state.filter.year.filter(e => e !== state.filter.yearSelection);
                        else if(!state.filter.year.includes(state.filter.yearSelection))
                            state.filter.year.push(state.filter.yearSelection);

                        const otherYear = state.filter.yearSelection === 'yearStart' ? 'yearEnd' : 'yearStart';
                        if(state.filter[otherYear] > 0 
                            && state.filter[state.filter.yearSelection] > 0
                            && state.filter.yearStart > state.filter.yearEnd)
                            state.filter[otherYear] = state.filter[state.filter.yearSelection];
                        
                        state.filter.yearSelection = '';
                        state.loaded = false;
                    } else if(selectedValue === 'reset') {
                        state.filter.yearStart = -1;
                        state.filter.yearEnd = -1;
                        state.filter.year = [];
                        state.filter.yearSelection = '';
                        state.loaded = false;
                    } else if(['yearUnavailable', 'yearUnsure'].includes(selectedValue)) {
                        state.filter.year = state.filter.year.includes(selectedValue) ? [] : [selectedValue];
                        state.filter.yearStart = -1;
                        state.filter.yearEnd = -1;
                        state.loaded = false;
                    }
                    break;
                case 'category':
                    if(selectedValue === 'categorySelection') {
                        state.filter.categorySelection = true;
                    } else if(selectedValue === 'reset') {
                        state.filter.selectedCategories = [];
                        state.filter.categorySelection = false;
                        state.loaded = false;
                    } else {
                        state.filter.selectedCategories = selectedValue;
                        state.filter.categorySelection = false;
                        state.loaded = false;
                    }
                    break;
                case 'categoryItem':
                    state.filter.selectedCategories = state.filter.selectedCategories.filter(e => e.id !== selectedValue);
                    state.loaded = false;
                    break;
                case 'textSearch':
                    state.filter.filter = selectedValue;
                    state.loaded = false;
                    break;
                default:
                    console.log(`Unmapped filter selected: ${selectedFilter}`);
            }
        },
        hideYearSelection: (state, action) => {
            state.filter.yearSelection = '';
        },
        orderSelected: (state, action) => {
            state.orderCol = action.payload.orderCol;
            state.orderDir = action.payload.orderDir;
            state.loaded = false;
        },
        hideCategorySelection: (state, action) => {
            state.filter.categorySelection = false;
        },
        hideVersionSelection: (state, action) => {
            state.filter.versionSelection = false;
        },
        favoriteUpdateRequested: (state, action) => {
            state.favoriteUpdate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadMachines.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadMachines.fulfilled, (state, action) => {
                state.status = 'idle';
                state.loaded = true;
                state.machines = [];
                let counter = 0;
                state.machines = action.payload.data.machines.map( (g) => { return {...g, 'i': counter++} } );
                state.total = action.payload.data.total;
            })
            .addCase(loadMachines.rejected, (state) => {
                state.status = 'idle';
                state.loaded = true;
            })
            .addCase(loadCategories.pending, (state) => {
                state.categoryListStatus = 'loading';
            })
            .addCase(loadCategories.fulfilled, (state, action) => {
                state.categoryListStatus = 'idle';
                state.categoryListLoaded = true;
                state.categoryList.data = action.payload.data.system;
            })
            .addCase(loadCategories.rejected, (state) => {
                state.categoryListStatus = 'idle';
                state.categoryListLoaded = true;
            })
            .addCase(postFavorite.fulfilled, (state, action) => {
                state.machines = state.machines.map( (g) => { return {...g, 'favorite': g.name === state.favoriteUpdate || g.favorite} } );
                state.favoriteUpdate = null;
            })
            .addCase(postFavorite.rejected, (state, action) => {
                state.favoriteUpdate = null;
            })
            .addCase(deleteFavorite.fulfilled, (state, action) => {
                const i = state.machines.findIndex(e => e.name === state.favoriteUpdate);
                state.machines[i].favorite = false;
                state.favoriteUpdate = null;
            })
            .addCase(deleteFavorite.rejected, (state, action) => {
                state.favoriteUpdate = null;
            })
            .addMatcher(
                (action) => action.type === 'login/auth/fulfilled',
                (state, action) => {
                    state.page = 1;
                    state.loaded = false;    
                }
            )
            .addMatcher(
                (action) => action.type === 'login/logout',
                (state, action) => {
                    state.page = 1;
                    state.filter.misc = state.filter.misc.filter(e => e !== 'favorite')
                    state.loaded = false;    
                }
            )
    },
});

export const { pageSelected, filterSelected, hideYearSelection, hideCategorySelection, hideVersionSelection, orderSelected, rendererSelected, favoriteUpdateRequested } = homeListSlice.actions;
export const selectRenderer = (state) => state.homeList.renderer;
export const selectMachines = (state) => state.homeList.machines;
export const selectTotal = (state) => state.homeList.total;
export const selectPage = (state) => state.homeList.page;
export const selectPageSize = (state) => state.homeList.pageSize;
export const selectPaginatorSize = (state) => state.homeList.paginatorSize;
export const selectLoaded = (state) => state.homeList.loaded;
export const selectStatus = (state) => state.homeList.status;
export const selectFilter = (state) => state.homeList.filter;
export const selectOrderCol = (state) => state.homeList.orderCol;
export const selectOrderDir = (state) => state.homeList.orderDir;
export const selectCategoryList = (state) => state.homeList.categoryList;
export const selectCategoryListStatus = (state) => state.homeList.categoryListStatus;
export const selectCategoryListLoaded = (state) => state.homeList.categoryListLoaded;
export default homeListSlice.reducer;