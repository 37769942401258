import React, { Component } from 'react';

class Settings extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div class="py-5">
        Settings component
      </div>
    );
  }
}

export default Settings;