import axios from 'axios';

import catalog from './catalog.json';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
class CustomError extends Error {
    constructor(code = '00000', ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError);
        }

        this.name = 'CustomError';
        // Custom debugging information
        this.code = code;
        this.date = new Date();
    }
}

export async function apiManager(api, request) {
    const apiName = api.name;
    const method = api.method;
    const host = window.location.host;
    const baseUrl = catalog.hosts[host] == null ? catalog.hosts.default : catalog.hosts[host];
    if (catalog.api[apiName] == null) {
        const errorCode = '10001';
        const message = 'Api ' + apiName + ' not found';
        return Promise.reject(new CustomError(errorCode, message));
    }
    if (catalog.api[apiName][method] == null) {
        return Promise.reject(Error('Method ' + method + ' not available for api ' + apiName));
    }
    if (catalog.api[apiName][method].mock === true) {
        if (catalog.api[apiName][method].mockPath == null) {
            return Promise.reject(Error('Mock path not defined for ' + apiName + '/' + method));
        } else {
            const response = await axios.get(catalog.api[apiName][method].mockPath);
            return response;
        }
    }
    let path = catalog.api[apiName][method].path;
    const pathVariables = path.match(/:[a-z0-9]+/ig);
    let requestVariables = request.variables;
    if (pathVariables != null) {
        for (let i = 0; i < pathVariables.length; i++) {
            const variableName = pathVariables[i].substring(1);
            if (requestVariables == null || requestVariables[variableName] == null) {
                return Promise.reject(Error('Path variable ' + variableName + ' not found in request'));
            } else {
                path = path.replace(pathVariables[i], requestVariables[variableName]);
                delete requestVariables[variableName];
            }
        }
    }
    //let queryParameters = '';
    //if(Object.keys(requestVariables).length){
    //    queryParameters = '?' + Object.keys(requestVariables).map((i) => ('' + i + '=' + requestVariables[i])).join('&')
    //}
    let headers = request.headers || {};
    if(!headers.Authorization && localStorage.getItem('token'))
        headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    let requestConfig = {
        url: path,
        method: method,
        baseURL: baseUrl,
        params: requestVariables,
        timeout: 5000,
        headers: headers,
    }
    if (method !== 'get') {
        requestConfig.data = request.data != null ? request.data : {};
    }
    const response = await axios(requestConfig).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return Promise.reject(Error(`${error.response.data.error} (Http status ${error.response.status})`));
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            return Promise.reject(Error("No response from server"));
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            return Promise.reject(Error(`Error: ${error.message}`));
        }
    });
    return response;
}