import React, { useEffect, useState, useRef } from 'react';
import {
    Link,
    useParams
} from "react-router-dom";

import {
    Col,
    Row,
    Container,
    Alert,
    Spinner
} from 'react-bootstrap';

import { loadDetail, emuOutStream, runGameImpl } from '../../api/game';

export default function GameDetail() {

    const [loaded, setLoaded] = useState(false);   // initial page loading
    const [error, setError] = useState(false);     // error messages will be stored here
    const [name, setName] = useState(null);        // rom data
    const [romof, setRomof] = useState(null);
    const [runnerAvailable, setRunnerAvailable] = useState(false); // true when emulator frontend is running
    const [stdout, setStdout] = useState([]);      // frontend output
    const [lastTick, setLastTick] = useState(+ new Date());
    const [running, setRunning] = useState(false); // true when emulator is running
    let { game } = useParams();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            loadDetail(game).then(function (response) {
                setName(response.data.name);
                setRomof(response.data.romof);
                emuOutStream().then(function (response) {
                    setRunnerAvailable(true);
                })
                .catch(function (error) {
                    // handle error
                    setError(`Emulator runner not available - error: ${error.response ? error.response.status : error.message}`);
                });
            })
            .catch(function (error) {
                // handle error
                setError(`Load game detail - error: ${error.response.status}`);
            });
        }
    });

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    useInterval(() => {
        if(running) {
            emuOutStream().then(function (response) {
                let tmp = stdout;
                response.data.stdout.map((e) => {
                    tmp.push(e);
                });
                setStdout(tmp);
                setRunning(response.data.runflag);
                setLastTick(+ new Date());
            });
        }
    }, 1000);

    useEffect(
        () => {
            if(runnerAvailable) {
                runGameImpl(name, romof).then(function (response) {
                    setRunning(true);
                })
                .catch(function (error) {
                    // handle error
                    setError(`Emulator runner - error: ${error.response ? error.response.status : error.message}`);
                });
            }
        },
        [runnerAvailable]
    );

    let alert = <div></div>
    if(error) {
        alert = (
            <Alert variant="danger">
                {error}
            </Alert>
        );
    }
    const lines = stdout.map((i) => {return <div>{i}</div>});
    let spinner = <div></div>;
    if(running) {
        spinner = (
            <Spinner animation="border" size="sm" />
        );
    }
    return(
        <Container>
            <Row className="py-3">
                <Col xs={12}>
                    {alert}
                    {lines}
                    {spinner}
                </Col>
            </Row>
        </Container>
    );
}