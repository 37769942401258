/*
*
*                 |                paginator size                    |
*            1    |tail                                          head|                 pages
*           [][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][][]
*                                       ^
*                                       currentPage
*                 |      tail size     ||         head size          |
*
*/

export default function paginatorConfigurator(currentPage, paginatorSize, totalRecords, itemsPerPage) {
    const pages = Math.ceil(totalRecords / itemsPerPage);
    const tailSize = Math.floor(paginatorSize / 2);
    const headSize = paginatorSize - tailSize;
    let tail = -1;
    if(currentPage - tailSize < 1) {
        tail = 1;
    } else if(currentPage - tailSize + paginatorSize > pages) {
        tail = pages - paginatorSize + 1 < 1 ? 1 : pages - paginatorSize + 1;
    } else {
        tail = currentPage - tailSize;
    }
    const head = tail + paginatorSize - 1 >= pages ? pages : tail + paginatorSize - 1;
    return {
        "pages": pages,
        "tail": tail,
        "head": head,
        "tailSize": tailSize
    }
}