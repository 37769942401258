import axios from 'axios';
import {basePath} from './conf';

export async function postCategory(r) {
    const response = await axios.post(`${basePath}/category/${r.category}/${r.operation}/game/${r.name}`);
    return response;
}

export async function getSysCategory() {
    const response = await axios.get(`${basePath}/category/type/system/all`);
    return response;
}
