import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiManager } from '../apiManager';

const initialState = {
    game: {
        loaded: false,
        status: 'idle',
        data: {},
    },
    screen: {
        loaded: false,
        status: 'idle',
        data: [],
    },
    romset: {
        loaded: false,
        status: 'idle',
        data: {},
    },
    romsetUpdates: {
        loaded: false,
        status: 'idle',
        current: null,
        currentIndex: null,
        target: null,
        targetIndex: null,
        data: [],
    },
    categories: {
        loaded: false,
        status: 'idle',
        data: {
            system: [],
            user: []
        },
    }
};

export const loadGame = createAsyncThunk(
    'game/load',
    async (gameName) => {
        const api = {
            "name": "machineDetail",
            "method": "get"
        }
        const r = {
            "variables": {
                "name": gameName
            },
            "data": {}
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const loadScreen = createAsyncThunk(
    'game/loadScreen',
    async (gameName) => {
        const api = {
            "name": "machineScreen",
            "method": "get"
        }
        const r = {
            "variables": {
                "name": gameName
            },
            "data": {}
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const loadRomset = createAsyncThunk(
    'game/loadRomset',
    async (req) => {
        const api = {
            "name": "machineRomset",
            "method": "get"
        }
        const r = {
            "variables": {
                "name": req.gameName,
                "version": req.version
            },
            "data": {}
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const loadRomsetUpdates = createAsyncThunk(
    'game/loadRomsetUpdates',
    async (gameName) => {
        const api = {
            "name": "romsetUpdates",
            "method": "get"
        }
        const r = {
            "variables": {
                "name": gameName
            },
            "data": {}
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const loadMachineCategories = createAsyncThunk(
    'game/loadMachineCategories',
    async (gameName) => {
        const api = {
            "name": "machineCategories",
            "method": "get"
        }
        const r = {
            "variables": {
                "name": gameName
            },
            "data": {}
        }
        const response = await apiManager(api, r);
        return response;
    }
);

export const gameDetailSlice = createSlice({
    name: 'gameDetail',
    initialState,
    reducers: {
        reloadGameDetail: (state) => {
            state.game = {loaded: false, status: 'idle', data: {}};
            state.screen = {loaded: false, status: 'idle', data: []};
            state.romset = {loaded: false, status: 'idle', data: {}};
            state.romsetUpdates = {loaded: false, status: 'idle', current: null, data: {}};
            state.categories = {loaded: false, status: 'idle', data: []}
        },
        changeRomsetVersion: (state, action) => {
            if(action.payload !== state.romsetUpdates.current && state.romset.loaded) {
                state.romsetUpdates.target = action.payload;
                state.romsetUpdates.targetIndex = state.romsetUpdates.data.findIndex(e => e.buildSeq === action.payload);
                if(!state.romset.data.hasOwnProperty(action.payload)) {
                    state.romset.loaded = false;    
                } else {
                    state.romsetUpdates.current = action.payload;
                    state.romsetUpdates.currentIndex = state.romsetUpdates.data.findIndex(e => e.buildSeq === action.payload);
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadGame.pending, (state) => {
                state.game.status = 'loading';
            })
            .addCase(loadGame.fulfilled, (state, action) => {
                state.game.status = 'idle';
                state.game.loaded = true;
                state.game.data = action.payload.data.machine;
            })
            .addCase(loadScreen.pending, (state) => {
                state.screen.status = 'loading';
            })
            .addCase(loadScreen.fulfilled, (state, action) => {
                state.screen.status = 'idle';
                state.screen.loaded = true;
                state.screen.data = action.payload.data.display;
            })
            .addCase(loadRomset.pending, (state) => {
                state.romset.status = 'loading';
            })
            .addCase(loadRomset.fulfilled, (state, action) => {
                state.romset.status = 'idle';
                state.romset.loaded = true;
                if(Array.isArray(action.payload.data.romset) && action.payload.data.romset.length > 0) {
                    const version = action.payload.data.romset[0].buildSeq;
                    state.romset.data[version] = action.payload.data.romset;
                }
                state.romsetUpdates.current = state.romsetUpdates.target;
                state.romsetUpdates.currentIndex = state.romsetUpdates.targetIndex;
            })
            .addCase(loadRomsetUpdates.pending, (state) => {
                state.romsetUpdates.status = 'loading';
            })
            .addCase(loadRomsetUpdates.fulfilled, (state, action) => {
                state.romsetUpdates.status = 'idle';
                state.romsetUpdates.loaded = true;
                state.romsetUpdates.data = action.payload.data.updates.reverse();
                state.romsetUpdates.current = state.romsetUpdates.data[state.romsetUpdates.data.length - 1].buildSeq;
                state.romsetUpdates.currentIndex = state.romsetUpdates.data.length - 1;
                state.romsetUpdates.target = state.romsetUpdates.current;
                state.romsetUpdates.targetIndex = state.romsetUpdates.currentIndex;
            })
            .addCase(loadMachineCategories.pending, (state) => {
                state.categories.status = 'loading';
            })
            .addCase(loadMachineCategories.rejected, (state) => {
                state.categories.status = 'idle';
                state.categories.loaded = true;
                state.categories.data.system = [];
                state.categories.data.user = [];
            })
            .addCase(loadMachineCategories.fulfilled, (state, action) => {
                state.categories.status = 'idle';
                state.categories.loaded = true;
                state.categories.data = action.payload.data;
                if(state.categories.data.system === null)
                    state.categories.data.system = [];
                if(state.categories.data.user === null)
                    state.categories.data.user = [];
            })
            .addMatcher(
                (action) => action.type === 'login/auth/fulfilled' || action.type === 'login/logout',
                (state, action) => {
                    state.categories.loaded = false;
                    state.categories.data.system = [];
                    state.categories.data.user = [];
                }
            );
    },
});

export const { reloadGameDetail, changeRomsetVersion } = gameDetailSlice.actions;
export const selectGameState = (state) => state.gameDetail.game;
export const selectScreenState = (state) => state.gameDetail.screen;
export const selectRomsetState = (state) => state.gameDetail.romset;
export const selectRomsetUpdatesState = (state) => state.gameDetail.romsetUpdates;
export const selectCategoriesState = (state) => state.gameDetail.categories;
export default gameDetailSlice.reducer;